<script>
import { PortableText } from '@portabletext/svelte';
import { onMount } from 'svelte';
import { urlFor } from '$lib/sanity';

export let section;

let formPromise;
let succesfullySubmitted;
let container;
let form;

async function subscribeNewsletter(event) {
  if (!checkIfFormIsValid(event)) {
    return false;
  }

  const formData = new FormData(event.target);

  const data = {};

  for (let field of formData) {
    const [key, value] = field;
    data[key] = value;
  }

  data.newsletter = true;

  const response = await fetch('/api/subscribe-newsletter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      formData: data,
    }),
  });

  let res = await response.json();

  if (res.statusCode == 202) {
    succesfullySubmitted = true;
    return true;
  } else {
    throw new Error('error');
  }
}

function checkIfFormIsValid(pEvent) {
  if (pEvent.target.dataset.valid) {
    return true;
  } else {
    return false;
  }
}

onMount(() => {
  function setUpFormValidation(pForm) {
    let form = pForm;

    const validation = {
      formIsValid: function () {
        let isValid = true;

        form.querySelectorAll('input-wrapper').forEach((elem) => {
          if (elem.classList.contains('not-valid')) {
            isValid = false;
          }
        });

        form.querySelectorAll('[required]').forEach((elem) => {
          if (elem.value <= 0) {
            isValid = false;
          }

          if (elem.type == 'checkbox' && elem.checked == false) {
            isValid = false;
          }
        });

        return isValid;
      },
      isEmailAddress: function (pString) {
        const pattern = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,3}/;
        return pattern.test(pString);
      },
      isPhoneNumber: function (pString) {
        const pattern = /^\d+\.?\d*$/;
        const length = 10;
        if (pString.length == length && pattern.test(pString)) {
          return true;
        } else {
          return false;
        }
      },
      hasLength: function (pString) {
        return pString.length > 5 ? true : false;
      },
    };

    function checkInput(pElem) {
      let elem = pElem;

      function toggleElemClass(pElem, pIsValid) {
        if (pIsValid) {
          pElem.closest('.input-wrapper').classList.remove('not-valid');
          pElem.closest('.input-wrapper').classList.add('is-valid');
        } else {
          pElem.closest('.input-wrapper').classList.add('not-valid');
          pElem.closest('.input-wrapper').classList.remove('is-valid');
        }
      }

      if (elem.type == 'email') {
        if (
          validation.hasLength(elem.value) &&
          validation.isEmailAddress(elem.value)
        ) {
          toggleElemClass(elem, true);
        } else {
          toggleElemClass(elem);
        }
      }

      if (elem.type == 'text') {
        if (validation.hasLength(elem.value)) {
          toggleElemClass(elem, true);
        } else {
          toggleElemClass(elem);
        }
      }

      if (elem.type == 'tel') {
        if (validation.isPhoneNumber(elem.value)) {
          toggleElemClass(elem, true);
        } else {
          toggleElemClass(elem);
        }
      }

      if (elem.type == 'textarea') {
        if (validation.hasLength(elem.value)) {
          toggleElemClass(elem, true);
        } else {
          toggleElemClass(elem);
        }
      }

      form.dataset.valid = validation.formIsValid();
    }

    // Prevents users from typing letters in "tel" input field
    form.querySelectorAll('input').forEach((elem) => {
      elem.addEventListener(
        'blur',
        () => {
          setTimeout(() => {
            checkInput(elem);
          }, 200);
        },
        false
      );
    });

    form.querySelectorAll('textarea').forEach((elem) => {
      elem.addEventListener(
        'blur',
        () => {
          checkInput(elem);
        },
        false
      );
    });
  }

  setUpFormValidation(form);
});
</script>

<section class="newsletter-section" id="newsletter">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="newsletter-section__inner">
          <div class="newsletter-section__copy">
            <h2 class="newsletter-section__copy__headline">
              {section.headline}
            </h2>
            <div class="newsletter-section__copy__body">
              <PortableText value={section.body} />
            </div>
          </div>

          <div class="newsletter-section__form-container">
            {#await formPromise then value}
              {#if value !== undefined}
                <div class="newsletter-section__form-alerts">
                  <alert class="newsletter-section__form__success is-visible">
                    {section.success_state.headline}
                  </alert>
                </div>
              {/if}
            {:catch error}
              <div class="newsletter-section__form-alerts">
                <alert class="newsletter-section__form__error is-visible">
                  There was an issue with the server when submitting your form.
                  Please refresh this page and try again.
                </alert>
              </div>
            {/await}

            <form
              class:is-hidden={succesfullySubmitted}
              bind:this={form}
              on:submit|preventDefault={(event) => {
                formPromise = subscribeNewsletter(event);
              }}
              class="newsletter-section__form"
              name="newsletter-signup"
              aria-label="Sign up for the Varo newsletter."
            >
              <div class="newsletter-section__form__inner">
                <div class="newsletter-section__form__section">
                  <div class="input-wrapper">
                    <label for="newsletter_email"> Email </label>
                    <input
                      type="email"
                      id="newsletter_email"
                      name="email"
                      required
                      class="input--primary  input--medium"
                    />
                    <alert> Please enter a valid email address. </alert>
                  </div>
                  <div class="newsletter-section__form__submit">
                    <button
                      type="submit"
                      class="button--tertiary  button--medium"
                    >
                      Sign Up
                    </button>
                  </div>
                </div>

                <div class="newsletter-section__form__section">
                  <div class="newsletter-section__form__disclaimer">
                    <div class="disclaimer-acceptance">
                      <input
                        type="checkbox"
                        name="gdpr-compliance"
                        id="gdpr-compliance"
                        required
                      />
                      <label class="disclaimer" for="gdpr-compliance">
                        {section.consent}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.disclaimer-acceptance label {
  cursor: pointer;
}
.disclaimer-acceptance {
  align-items: flex-start;
}

.disclaimer-acceptance input {
  margin-top: 0.25rem;
}

form.is-hidden {
  display: none;
}

.newsletter-section__form-container {
  width: 100%;
  margin-top: 0.5rem;
}

@media screen and (min-width: 768px) {
  .newsletter-section__form-container {
    margin-top: 0.5rem;
  }
}

.newsletter-section__form-alerts {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsletter-section__form__error {
  background-color: #b73d37;
  border-radius: 8px;
  padding: 0.75rem;
  height: auto;
  width: 100%;
  max-width: 25rem;
  display: block;
  margin-bottom: 1.25rem;
  color: #fff;
  font-size: 0.9rem;
  line-height: 1.5;
}

.newsletter-section__form__success {
  text-align: left;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .newsletter-section__form__success {
    text-align: center;
  }
}
</style>
